
































































































































import { Vue, Component, Mixins } from 'vue-property-decorator'

// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'
// @ts-ignore
import MyDvBorder9 from '$ui/dv/packages/my-dv-border9'
// @ts-ignore
import MyDvTitle from '$ui/dv/packages/my-dv-title'

// @ts-ignore
import MyDvLoading from '$ui/dv/packages/my-dv-loading'

// @ts-ignore
import MyDvNumber from '$ui/dv/packages/my-dv-number'

import MyBox from '@/components/box.vue'

import MyList from '@/mixins/list'

import Amap from '@amap/amap-vue/lib/amap'

import UserList from './components/user_list.vue'

@Component({
  components: {
    MyDvBox,
    MyDvBorder9,
    MyDvTitle,
    MyDvLoading,
    MyBox,
    MyDvNumber,
    Amap,
    UserList,
  },
})
export default class extends Mixins(MyList) {
  mode: string = ''

  changeMode(mode: string) {
    this.mode = mode
  }

  mounted() {}
}
